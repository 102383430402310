.containerStyle {
  justify-content: center;
  gap: 12px;
}

.inputStyle {
  width: 100% !important;
  max-width: 80px;
  height: 80px;
  border-radius: 8px;
  border: 2px solid #e6e9ed;
  outline: none;
  font-size: 40px;
}

.inputStyle:focus {
  border: 2px solid #6a87ff;
}

.inputStyle:valid {
  border: 2px solid #c9d3ff;
}
