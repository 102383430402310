:root {
  --BW-White: #fff;
  --BW-Black: #2c3957;
  --BW-Black-700: #556282;
  --BW-Black-500: #9ba8b7;
  --BW-Black-400: #d0d6dd;
  --BW-Black-300: #e6e9ed;
  --BW-Black-100: #f1f3f9;
  --Main-Blue: #6a87ff;
  --Main-Blue-50: #5770d9;
  --Main-Blue-40: #8aa1ff;
  --Main-Blue-30: #c9d3ff;
  --Main-Blue-10: #f1f3ff;
  --Main-Steel: #b5c1da;
  --Main-Azureish: #dae6ef;
  --Accent-Peach: #f8cd91;
  --Accent-Teal: #397d8c;
  --Accent-Red: #de4355;
  --Gray-700: #344054;
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #c9d3ff;
  --toastify-text-color-info: #6a87ff;
  --toastify-color-success: #c2e7e9;
  --toastify-text-color-success: #397d8c;
  --toastify-color-warning: #f8cd91;
  --toastify-color-error: #fde2e2;
  --toastify-text-color-error: #de4355;
  --toastify-toast-width: 460px;
  --toastify-toast-min-height: 48px;
}
.Toastify__toast-body {
  padding: 0;
  font-size: 14px;
  font-weight: 700;
}

.Toastify__toast-icon {
  width: 32px;
}

*::-webkit-scrollbar {
  margin-left: 8px;
  width: 4px;
  border-radius: 8px;
  background-color: var(--BW-Black-100);
}

*::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 8px;
  background-color: var(--BW-Black-400);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--BW-Black-400);
}

*::-webkit-scrollbar-thumb:active {
  background-color: var(--BW-Black-400);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--BW-Black-400);
}

*::-webkit-scrollbar-corner {
  background-color: var(--BW-Black-400);
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

main {
  padding-top: 104px;
}

a {
  text-decoration: none;
}
@media screen and (max-width: 900px) {
  main {
    padding-top: 78px;
  }
}

.next-image {
  width: 100%;
  height: auto;
}

.footer_logo {
  width: 115px;
  height: 25px;
}

@media screen and (max-width: 900px) {
  .footer_logo {
    width: 85px;
    height: 20px;
  }
}
